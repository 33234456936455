import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { ENV_NAME } from "../../constants";

const Header = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        ></IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Kallpa Pay Web Admin
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#fbbf50",
          }}
        >
          {ENV_NAME}
        </Typography>
        <Button color="inherit">Database</Button>
        <Button color="inherit">Swan Queries</Button>
        <Button
          color="inherit"
          onClick={() => navigate("/dashboard/cause-onboarding")}
        >
          Cause Onboarding
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
