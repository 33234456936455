import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";
import { CauseInputDto } from "../entities/CauseInputDto";
import { CauseTaxReceiptDto } from "../entities/CauseTaxReceiptDto";

async function getCauseForMandateCreation() {
    const response = await axiosInstance.get(
        `/causes/without-mandate`,
        HEADER_JSON
    );
    return response.data;
}

async function createCause(CauseInputDto: CauseInputDto) {
    const response = await axiosInstance.post(
        `/causes`,
        CauseInputDto,
        HEADER_JSON
    );
    return response.data;
}

async function getCauseForTaxReceiptData() {
    const response = await axiosInstance.get(
        `/causes/tax-receipt-data`,
        HEADER_JSON
    );
    return response.data;
}

async function updateCauseForTaxReceiptData(causeTaxReceiptDto: CauseTaxReceiptDto) {
    const response = await axiosInstance.post(
        `/causes/tax-receipt-data`,
        causeTaxReceiptDto,
        HEADER_JSON
    );
    return response.data;
}


export { getCauseForMandateCreation, createCause, getCauseForTaxReceiptData, updateCauseForTaxReceiptData };