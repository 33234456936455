import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";

async function getAccountHoldersForCauseCreation() {
    const response = await axiosInstance.get(
        `/account-holder/cause-creation`,
        HEADER_JSON
    );
    return response.data;
}

export { getAccountHoldersForCauseCreation };