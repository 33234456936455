import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";

async function getCauseLegalEnttiyCategories() {
    const response = await axiosInstance.get(
        `/causes-legal-entity/categories`,
        HEADER_JSON
    );
    return response.data;
}

async function getCauseLegalEnttiySubcategories() {
    const response = await axiosInstance.get(
        `/causes-legal-entity/subcategories`,
        HEADER_JSON
    );
    return response.data;
}

export { getCauseLegalEnttiyCategories, getCauseLegalEnttiySubcategories };