interface IMandateCreationInputDto {
    causeId: string;
    swanUserId: string;
}

export class MandateCreationInputDto implements IMandateCreationInputDto {
    constructor(
        public causeId: string,
        public swanUserId: string
    ) {}
}