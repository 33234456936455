import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { FormHelperText, OutlinedInput } from "@mui/material";

interface MultiSelectComponentProps {
  name: string;
  label: string;
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  options: string[];
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const MultiSelectComponent: React.FC<MultiSelectComponentProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  error = false,
  helperText = "",
  disabled = false
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        multiple
        value={value}
        onChange={onChange}
        disabled={disabled}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((val) => (
              <Chip key={val} label={val} />
            ))}
          </Box>
        )}
        error={error}
        input={
          <OutlinedInput id={`select-${name}-multiple-chip`} label={label} />
        }
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormControl error={!!error}>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </FormControl>
  );
};
