import logo from "../../assets/images/white.svg";
import "./Home.css";
import { GoogleLogin } from "@react-oauth/google";
import { getLogin } from "../../services/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV_NAME } from "../../constants";

interface HomeProps {
  onLogin: (authResponse: AuthResponse) => void;
}

function Home({ onLogin }: Readonly<HomeProps>) {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleSuccess = async (response: any) => {
    const login: LoginDto = {
      credential: response.credential,
    };
    try {
      const auth = await getLogin(login);
      if (auth) {
        onLogin(auth);
        navigate("/dashboard");
      } else {
        setValue("Oups something hapen, login failed");
      }
    } catch {
      setValue("Oups something hapen, login failed");
    }
  };

  const handleError = () => {
    setValue("Oups something hapen, login failed");
  };

  return (
    <div className="Background">
      <img src={logo} className="Logo" alt="logo" />
      <div className="Title-group">
        <h1 className="Title">Web Admin</h1>
        <h1 className="Env-style"> {ENV_NAME?.toUpperCase()}</h1>
      </div>

      <div className="Connexion-btn">
        <GoogleLogin
          size="large"
          shape="pill"
          locale="en_EN"
          hosted_domain="kallpafinance.com"
          theme="filled_blue"
          onSuccess={handleSuccess}
          onError={handleError}
        />
        {value !== "" && <p className="Error">{value}</p>}
      </div>
    </div>
  );
}

export default Home;
