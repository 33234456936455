import { useState } from "react";
import "./App.css";
import { LOCAL_STORAGE_EXPIRATION_TIME } from "./constants";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Components/Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";
import Onboarding from "./Components/Dashboard/Onboarding/Onboarding";
import ProtectedRoute from "./config/ProtectedRoute";
import AuthProvider from "./config/AuthProvider";
import Header from "./Components/Dashboard/Header";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const [expirationTime, setExpirationTime] = useState(
    localStorage.getItem(LOCAL_STORAGE_EXPIRATION_TIME)
  );

  const handleLogin = (authResponse: AuthResponse) => {
    const newExpirationTimestamp =
      Date.now() + authResponse.accessTokenValidity;
    localStorage.setItem(
      LOCAL_STORAGE_EXPIRATION_TIME,
      newExpirationTimestamp.toString()
    );
    setExpirationTime(newExpirationTimestamp.toString());
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home onLogin={handleLogin} />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Header />
          <Dashboard />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "cause-onboarding/*",
          element: <Onboarding />,
        },
      ],
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider
        currentUser={expirationTime != null ? { isAuthenticated: true } : null}
      >
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
