import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

interface TextFieldComponentProps {
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  disabled?: boolean;
  type?: string;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  name,
  label,
  value,
  onChange,
  error = false,
  helperText = "",
  multiline,
  rows = 1,
  maxLength,
  disabled,
  type = "text",
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <TextField
        name={name}
        label={label}
        variant="outlined"
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        inputProps={{
          maxLength: maxLength,
        }}
        disabled={disabled}
        type={type}
        InputLabelProps={{
          shrink: type === "date" || value.length > 0,
        }}
      />
    </FormControl>
  );
};
