import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  Snackbar,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CauseWithoutMandateDto } from "../../../entities/CauseWithoutMandateDto";
import { UserAdminDto } from "../../../entities/UserAdminDto";
import { getCauseForMandateCreation } from "../../../services/causeService";
import { getUsersForMandateCreation } from "../../../services/userService";
import { MandateCreationInputDto } from "../../../entities/MandateCreationInputDto";
import { createMandate } from "../../../services/mandateService";
import { ConsentUrlAdminDto } from "../../../entities/ConsentUrlAdminDto";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";

function MandateCreation() {
  const [causes, setCauses] = useState<CauseWithoutMandateDto[]>([]);
  const [users, setUsers] = useState<UserAdminDto[]>([]);
  const [selectedCause, setSelectedCause] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [consentUrl, setConsentUrl] = useState<ConsentUrlAdminDto>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    async function fetchCauses() {
      try {
        const response = await getCauseForMandateCreation();
        setCauses(response);
        if (response.length > 0) {
          setSelectedCause(response[0].id);
        }
      } catch (error) {
        setErrorMessage("Could not fetch causes, with error: " + error);
      }
    }
    fetchCauses();
  }, []);

  useEffect(() => {
    if (selectedCause) {
      const fetchUsers = async () => {
        try {
          const response = await getUsersForMandateCreation(selectedCause);
          setUsers(response);
          if (response.length > 0) {
            setSelectedUser(response[0].id);
          }
        } catch (error) {
          setErrorMessage("Could not fetch users: " + error);
        }
      };
      fetchUsers();
    }
  }, [selectedCause]);

  const handleCauseChange = (event: SelectChangeEvent<string>) => {
    setConsentUrl(undefined);
    setSelectedCause(event.target.value);
  };

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setSelectedUser(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const input = new MandateCreationInputDto(selectedCause, selectedUser);
    try {
      const response = await createMandate(input);
      setConsentUrl(response);
    } catch (error) {
      setErrorMessage("Could not create mandate, with error: " + error);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(consentUrl!.consentUrl);
      setOpenSnackbar(true);
    } catch (error) {
      setErrorMessage("Failed to copy text:  " + error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography component="h2" variant="h2">
        Mandate Creation
      </Typography>
      <form>
        <InputLabel id="causes-label" htmlFor="causes-select" sx={{ pt: 3 }}>
          Causes
        </InputLabel>
        <FormControl fullWidth margin="normal">
          <Select
            id="causes-select"
            labelId="causeId"
            value={selectedCause}
            onChange={handleCauseChange}
          >
            {causes.map((cause) => (
              <MenuItem key={cause.id} value={cause.id}>
                {cause.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputLabel id="users-label" htmlFor="users-select" sx={{ pt: 3 }}>
          Users
        </InputLabel>
        <FormControl fullWidth margin="normal">
          <Select
            id="users-select"
            labelId="userId"
            value={selectedUser || ""}
            onChange={handleUserChange}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
      {consentUrl?.consentUrl && (
        <>
          <Typography component="p" variant="body1" mt={3}>
            URL Link to be transmitted :
          </Typography>
          <Typography component="p" variant="body2">
            <IconButton
              onClick={handleCopyClick}
              aria-label="copy"
              style={{ marginLeft: 8, height: 40 }}
            >
              <CopyIcon style={{ width: "100%", height: "100%" }} />
            </IconButton>
            {consentUrl.consentUrl}
          </Typography>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message="Text save in clipboard !"
          />
        </>
      )}

      <FormControl error={!!errorMessage}>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default MandateCreation;
