import React from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

interface SingleSelectComponentProps<T> {
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >) => void;
  options: T[];
  optionLabelKey: keyof T;
  optionValueKey: keyof T; 
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export function SingleSelectComponent<T>({
  name,
  label,
  value,
  onChange,
  options,
  optionLabelKey,
  optionValueKey,
  error = false,
  helperText = "",
  disabled = false,
}: SingleSelectComponentProps<T>) {
  return (
    <FormControl fullWidth margin="normal">
      <TextField
        id={name}
        select
        label={label}
        value={value}
        onChange={onChange}
        variant="outlined"
        error={error}
        disabled = {disabled}
      >
        {options.map((option) => (
          <MenuItem
            key={option[optionValueKey] as React.Key}
            value={option[optionValueKey] as string}
          >
            {option[optionLabelKey] as string}
          </MenuItem>
        ))}
      </TextField>
      {helperText && <p style={{ color: "red" }}>{helperText}</p>}
    </FormControl>
  );
}
