import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";

async function getLogin(LoginDto: LoginDto) {
    const response = await axiosInstance.post(
        `/auth/login`,
        LoginDto,
        HEADER_JSON
    );
    return response.data;
}

export { getLogin };