import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

interface CheckboxComponentProps {
  name: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  name,
  label,
  checked,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
    />
  );
};
