import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";
import { MandateCreationInputDto } from "../entities/MandateCreationInputDto";

async function createMandate(input: MandateCreationInputDto) {
    const response = await axiosInstance.post(
        `/mandates`,
        JSON.stringify(input),
        HEADER_JSON
    );
    return response.data;
}

export { createMandate };