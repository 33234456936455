import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";

async function getUsersForMandateCreation(causeId: string) {
    const response = await axiosInstance.get(
        `/users/mandate-authorized?cause-id=${causeId}`,
        HEADER_JSON
    );
    return response.data;
}

export { getUsersForMandateCreation };