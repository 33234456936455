import { createContext, PropsWithChildren, useContext } from "react";

import { User } from "../entities/User";

const AuthContext = createContext<User | null>(null);

type AuthProviderProps = PropsWithChildren & {
  currentUser: User | null;
};

export default function AuthProvider({
  children,
  currentUser,
}: AuthProviderProps) {
  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
