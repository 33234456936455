interface ICauseInputDto {
    name: string;
    legalName: string;
    description: string;
    causeCategoriesCode: string[];
    logo: string;
    accountHolderId: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: string;
    countryCode: string;
}

export class CauseInputDto implements ICauseInputDto {
    constructor(
        public name: string,
        public legalName: string,
        public description: string,
        public causeCategoriesCode: string[],
        public logo: string,
        public accountHolderId: string,
        public addressLine1: string,
        public addressLine2: string,
        public postCode: string,
        public city: string,
        public countryCode: string,
    ) {}
}