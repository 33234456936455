export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

export const HEADER_JSON = {
    headers: {
        "Content-Type": "application/json",
    }
};

export const LOCAL_STORAGE_EXPIRATION_TIME = 'exp_BCrDuewH176vvWsOs7b5';

export const ENV_NAME = process.env.REACT_APP_ENV_NAME;