import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { LOCAL_STORAGE_EXPIRATION_TIME } from '../constants';;

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  config.withCredentials = true;
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosResponse> => {
  const status = error.response ? error.response.status : 0;
        if (status === 401 || status === 403) {
          onErrorCheckSessionExpiration();
        }
  return Promise.reject(error);
};

const onErrorCheckSessionExpiration = () => {
  if (isSessionExpired()) {
    handleSessionExpired();
  }
};

const isSessionExpired = () => {
  const expirationTimestamp = localStorage.getItem(
    LOCAL_STORAGE_EXPIRATION_TIME
  );
  return !expirationTimestamp || Date.now() > parseInt(expirationTimestamp, 10);
};

const handleSessionExpired = () => {
  localStorage.removeItem(LOCAL_STORAGE_EXPIRATION_TIME);
  window.location.href = "/";
};

export default  setupInterceptorsTo ;